import Api from "./Api";
import CONST from "../const/control_panel/common";

export default {
  /**
   * message Insert
   * @param {*} form
   * @returns object
   */
  async register(form) {
    return Api().post("api/v1/events", form);
  },

  register_file(form,id) {

    return Api().post("api/v1/files/events/" + `${id}` , form);

},

  /**
   * All message list
   * @returns object
   */
   list() {
    return Api().get("api/v1/events?desc=created_at&status=公開" );
  },
  listNum(num) {
    return Api().get("api/v1/events?where=status@公開&desc=created_at&per=" + `${num}` );
  },
  listNumUser(num,user,order,status) {
    if(status!='all'){
        return Api().get("api/v1/events?where=create_user_id@"  + `${user}`+ "AND status@"  + `${status}` + "&" + order + "=created_at&per=" + `${num}` );
    }else{
        return Api().get("api/v1/events?where=create_user_id@"  + `${user}` + "&" + order + "=created_at&per=" + `${num}` );
    }
  },

  /**
   * Show the currect message
   * @returns object
   */
  show(id) {
    return Api().get("api/v1/events/" + `${id}`);
  },
  showfile(id,key,value) {
    return Api().get("api/v1/files/events/" + `${id}` +"?where="  + `${key}` + "@" + `${value}`);
  },

  /**
   * Edit the currect message
   * @returns object
   */
  edit(id) {
    return Api().get("api/v1/events/" + `${id}`);
  },

    /**
     * event Update 
     * @param {*} form ,id
     * @returns object
     */
    update(form,id) {
      return Api().put("api/v1/events/" + `${id}`, form);
    },

    update_file(form,message_id,file_id) {
      return Api().put("api/v1/files/events/" + `${message_id}` + "/" + `${file_id}`, form);
    },

    getFile(id) {
      return Api().get("api/v1/files/events/" + `${id}`);
    },



/**

* delete the currect event

* @returns boolean

*/

delete(id) {

  return Api().delete("api/v1/events/" + `${id}`);

},



file_delete(id,file_id) {

  return Api().delete("api/v1/files/events/" + `${id}`+ "/" + `${file_id}`);

},


entryGet(id) {

  return Api().get("api/v1/events/" + `${id}` + "/entry");

},

entryGetPer(id,status,paginate,page) {
  var url = "api/v1/events/" + `${id}` + "/entry?per=" + `${paginate}`;
  if(page != null){
    url = url + '&page=' + `${page}`;
  }
  if(status != 'all'){
    url += '&status=' + `${status}`;
  }
  return Api().get(url);
},

entryput(form,event_id,enter_id) {

  return Api().put("api/v1/events/" + `${event_id}` + "/entry/" + `${enter_id}`, form);

},


entryPost(form,id) {

  return Api().post("api/v1/events/" + `${id}` + "/entry" , form);

},

};
